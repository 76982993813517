<template>
  <div class="content">
    <div class="top">
      <div   style="margin: 20px;display: flex;justify-content: center">
        <div style="margin-right: 30px">
          <span style="margin-right: 10px">联系电话:</span>
          <span>400-1166-160</span>
        </div>
        <div style="margin-right: 30px">
          <span style="margin-right: 10px">邮箱:</span>
          <span>service@zct100.cn</span>
        </div>
        <div>
          <span style="margin-right: 10px">地址:</span>
          <span>北京市海淀区建西苑中里1号楼1层商业115</span>
        </div>
      </div>
<!--      <ul class="third">-->
<!--          <li>帮助中心</li>-->
<!--          <li v-for="(item,index) in navListHelp" :key="index" @click="skip(index,'help')">{{item.title}}</li>-->
<!--      </ul>-->
<!--      <ul class="fourth">-->
<!--          <li>关于我们</li>-->
<!--          <li v-for="(item,index) in navListAbout" :key="index" @click="skip(index,'about')">{{item.title}}</li>-->
<!--      </ul>-->
<!--      <ul class="fifth">-->
<!--          <li>联系我们</li>-->
<!--          <li>联系电话：18647128155</li>-->
<!--          <li>邮箱：service@zct100.cn</li>-->
<!--          <li>地址：北京市海淀区建西苑中里1号楼1层商业115</li>-->
<!--      </ul>-->

    </div>
    <hr />
      <div class="text">
        <span>版权声明&nbsp;&nbsp;&nbsp;&nbsp;  </span>
        <span><el-link href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: underline"> 京ICP备2023040729号-1 </el-link></span>
        <span><el-link href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043759"  target="_blank" style="text-decoration: underline; padding-left: 20px;">
          <img src="https://www.zct100.cn/m/test/static/images/gwab.png" style="float: left;padding-right: 5px;">
        京公网安备11010802043759号 </el-link>
        </span>
        <span> &nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证 京B2-20250437 </span>
        <span> &nbsp;&nbsp;&nbsp;&nbsp;北京招采通科技有限公司 </span>
      </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      navListHelp:[{title:'CA相关'},{title:'专家指南'},{title:'投标指南'}],
      navListAbout:[{title:'平台介绍'},{title:'联系我们'}],
    }
  },
  methods:{
    skip(activeName,type) {
      if (type=='help') {
        this.$router.push({
          path: "/help",
          query: {activeName: activeName },
        });
        this.$bus.$emit("changeMainNav", "/help");
      } else if(type=='about'){
        this.$router.push({
          path: "/ourself",
          query: {activeName: activeName },
        });
        this.$bus.$emit("changeMainNav", "/ourself");
      }


    },
  }
}
</script>
<style scoped lang="less">
a{
  text-decoration: none;
  color: white;
}
.content{
  background-color: #0F0F0F;
  width: 100%;
  //height: 300px;
  padding-bottom: 25px;
  color: #999999;
}
.top{
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

hr{
  background: -webkit-linear-gradient(left, #000000,#d7d7d7 , #ffffff,#d7d7d7,#000000 ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* 标准的语法 */
  border:none;
  height: 1px;
  opacity: 1;
}
.text{
  text-align: center;
  font-size: 14px;
  color: #999999;
  padding-top: 20px;
}
.text a{
  color: #FFFFFF;
}
</style>
